import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { Col, Container, Row } from "react-bootstrap";
import { FaUsers } from "react-icons/fa6";
import { PiUserSwitchFill } from "react-icons/pi";
import { MdAttachMoney } from "react-icons/md";
import { FaUserSlash } from "react-icons/fa";
import { BiMoneyWithdraw } from "react-icons/bi";
import { Data } from "../../Common/Data";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../Actions/adminActions";
import MyChart from "../../Component/MyChart/MyChart";
import Chart from "../../Component/MyChart/Chart";

const Dashboard = () => {
const dispatch =  useDispatch()
  const {dashboardData} = useSelector((state) => state.admin);
  useEffect(() => {
    
    dispatch(fetchDashboardData());
  }, []);

  return (
    <Container>
      <section>
        <h4 className="welcome">Welcome Back!</h4>
        <Row md="12">
          <Col md="3">
            <div className="dashboardAdminCard">
              <div>
                <h3>{dashboardData?.totalUsers}</h3>
                <p>Total Users</p>
              </div>
              <div className="dashboardIcon">
                <i>
                  <FaUsers />
                </i>
              </div>
            </div>
          </Col>
          <Col md="3">
            <div className="dashboardAdminCard">
              {" "}
              <div>
                <h3>{dashboardData?.activeUsers}</h3>
                <p>Active Users</p>
              </div>
              <div className="dashboardIcon">
                <i>
                  <PiUserSwitchFill />
                </i>
              </div>
            </div>
          </Col>
          <Col md="3">
            <div className="dashboardAdminCard">
              {" "}
              <div>
                <h3>{dashboardData?.todayActiveUsers|| "0"}</h3>
                <p>Today Active Users</p>
              </div>
              <div className="dashboardIcon">
                <i>
                <FaUsers />
                </i>
              </div>
            </div>
          </Col>
          <Col md="3">
            <div className="dashboardAdminCard">
              {" "}
              <div>
                <h3>{dashboardData?.blocked_user|| "0"}</h3>
                <p>Blocked Users</p>
              </div>
              <div className="dashboardIcon">
                <i>
                <FaUserSlash />
                </i>
              </div>
            </div>
          </Col>
        </Row>
        <Row md='12'>
        <Col md='6'>
        {dashboardData != null && dashboardData ? (
            <MyChart
              className="dashboardChart"
              totalWithdrawals={dashboardData?.totalWithdrawals}
              pendingWithdrawals={dashboardData?.pendingWithdrawals}
              approvedWithdrawals={dashboardData?.approvedWithdrawals}
              rejectedWithdrawals={dashboardData?.rejectedWithdrawals}
            />
          ) : (
            ""
          )}
        </Col>
        <Col md='6'>
        {dashboardData != null && dashboardData ? (
            <Chart
              className="dashboardChart"
              totalPaymentRequests={dashboardData?.totalPaymentRequests}
              pendingPaymentRequests={dashboardData?.pendingPaymentRequests}
              approvedPaymentRequests={dashboardData?.approvedPaymentRequests}
              rejectedPaymentRequests={dashboardData?.rejectedPaymentRequests}
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
        <section>
        <Row md="12">
          <Col md="6">
            <div className="dashboardInvestment">
              <div className="dashboardInvestmentHeading">
              <h3>INVESTMENT</h3>
              <div className="dashboardIcon">
                <i>
                  <MdAttachMoney />
                </i>
              </div>
              </div>
              <div className="dashboardInvestmentContent">
                <h4>Total </h4>
                <p>{dashboardData?.totalBet}</p>
              </div>
              <div className="dashboardInvestmentContent">
                <h4>Today</h4>
                <p>{dashboardData?.todayBet}</p>
              </div>
              <div className="dashboardInvestmentContent">
                <h4>Yesterday</h4>
                <p>{"0"}</p>
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="dashboardInvestment">
            <div className="dashboardInvestmentHeading">
              <h3>INCOME</h3>
              <div className="dashboardIcon">
                <i>
                  <MdAttachMoney />
                </i>
                </div>
              </div>
              <div className="dashboardInvestmentContent">
                <h4>Total </h4>
                <p>{dashboardData?.totalIncome}</p>
              </div>
              <div className="dashboardInvestmentContent">
                <h4>Today</h4>
                <p>{dashboardData?.todayIncome}</p>
              </div>
              <div className="dashboardInvestmentContent">
                <h4>Yesterday</h4>
                <p>{"0"}</p>
              </div>
            </div>
          </Col>
          {/*<Col md="4">
            <div className="dashboardInvestment">
              <h3>Withdrawl</h3>
              <div className="dashboardInvestmentContent">
                <h4>Active</h4>

                <p>{"0"}</p>
              </div>
            </div>
          </Col>*/}
          {/*<Col md="4">
            <div className="dashboardInvestment">
              <h3>Wallet</h3>
              <div className="dashboardInvestmentContent">
                <h4>Main Wallet</h4>
                <p>{"0"}</p>
              </div>
              <div className="dashboardInvestmentContent">
                <h4>Stacked Coin</h4>
                <p>{"0"}</p>
              </div>
              <div className="dashboardInvestmentContent">
                <h4>Repurchased Stacked Coin</h4>
                <p>{"0"}</p>
              </div>
            </div>
          </Col>*/}
        </Row>
      </section>
      </section>
      {/* <section>
        <div className="table">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Username</th>
                <th>Email</th>
                <th>Join Date</th>
              </tr>
            </thead>
          </table>
        </div>
</section> */}
      {/* <div>
        <h4>New Customer List</h4>
        <button>All</button>
      </div> */}
      {/*<section>
        <div>
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Username</th>
                <th>Email</th>
                <th>Join Date</th>
              </tr>
            </thead>
          </table>
        </div>
      </section>*/}
    </Container>
  );
};

export default Dashboard;
