import React, { useEffect, useState, useRef } from "react";
import "./Sidebar.css";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { AiFillHome } from "react-icons/ai";
import { IoIosPeople } from "react-icons/io";
import { BsFillCartCheckFill } from "react-icons/bs";
import { IoIosLogOut } from "react-icons/io";
import { BsChevronDown } from "react-icons/bs";
import { GiLetterBomb } from "react-icons/gi";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaMoneyBillAlt, FaMoneyCheckAlt } from "react-icons/fa";
import { BsBank2 } from "react-icons/bs";
import { RiFundsBoxFill } from "react-icons/ri";
import { RiRefund2Line } from "react-icons/ri";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { AiTwotoneFund } from "react-icons/ai";
import { GiReceiveMoney } from "react-icons/gi";
import { IoQrCodeSharp } from "react-icons/io5";
import { BiMoneyWithdraw } from "react-icons/bi";
import { ImImages } from "react-icons/im";
import { IoMdSettings } from "react-icons/io";
import NavPages from "../NavPages";
import { BsHddStackFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Change from "../Common/StringToSub";
import useWindowDimensions from "../Common/useWindowDimensions";
import { useSelector, useDispatch } from "react-redux";
import { Data } from "../Common/Data";
import { fetchDashboardData } from "../Actions/adminActions";

const Sidebar = () => {
  const { loading, error, user } = useSelector((state) => state.admin);
  const navigate = useNavigate();
  useEffect(() => {
    if (error?.code === 419) {
      navigate("/");
    }
  }, [error]);

  const userAddress = localStorage.getItem("userAddress");
  const [genelogyDropdown, setGenelogyDropdown] = useState("none");
  const [withdrawalDropdown, setwithdrawalDropdown] = useState("none");
  const [fundDropdown, setfundDropdown] = useState("none");
  const [payoutReportDropdown, setPayoutReportDropdown] = useState("none");
  // ----------------------------Dropdown Icons --------------------------------------
  const [genelogyDropdownIcon, setGenelogyDropdownIcon] = useState("0deg");
  const [fundDropdownIcon, setfundDropdownIcon] = useState("0deg");
  const [withdrawalDropdownIcon, setwithdrawalDropdownIcon] = useState("0deg");
  const { height, width } = useWindowDimensions();
  const hasFetchedData = useRef(false);
  const [payoutReportDropdownIcon, setPayoutReportDropdownIcon] =
    useState("0deg");
  const [toggle, setToggle] = useState("0");
  const Logout = () => {
    localStorage.clear();
    navigate("/", { replace: true });
  };

  useEffect(() => {
    if (width < 900) {
      setToggle("-260px");
    }
  }, [width]);

  const dispatch = useDispatch();
  const { dashboardData } = useSelector((state) => state.admin);
  useEffect(() => {
    if (!hasFetchedData.current) {
      dispatch(fetchDashboardData());
      hasFetchedData.current = true;
    }
  }, []);

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <div id="sidebar-wrapper" className="" style={{ left: toggle }}>
          <div id="sidebarTopDiv">
            <div className="logo">
              <img src={Data.logo} alt="logo.png" />
            </div>
          </div>
          <section style={{ padding: "5px", marginTop: "80px" }}>
            <div className="logowithtext">
              {/* <img src={Data.logo} alt="logo.png" /> */}
              <h3 className="m-0">
                User Name <div></div>
              </h3>
              <p>{Change(userAddress)} </p>
            </div>
            <NavLink
              to=""
              end
              activeClassName="active"
              onClick={() => (width < 800 ? setToggle("-260px") : "")}
            >
              <div className="items">
                <i>
                  <AiFillHome />
                </i>
                <h4 className="m-0">Dashboard</h4>
              </div>
            </NavLink>
            {/*<NavLink to="add_fund" activeClassName="active">
              <div className="items">
                <i>
                  <FaMoneyBillAlt />
                </i>
                <h4 className="m-0">Add Reward</h4>
              </div>
            </NavLink>
            <NavLink to="active_package" activeClassName="active">
              <div className="items">
                <i>
                  <FaMoneyBillAlt />
                </i>
                <h4 className="m-0">Add Package</h4>
              </div>
            </NavLink>*/}
            <div
              className="dropdownitems"
              onClick={() =>
                genelogyDropdown === "none"
                  ? (setGenelogyDropdown("block"),
                    setGenelogyDropdownIcon("180deg"))
                  : (setGenelogyDropdown("none"),
                    setGenelogyDropdownIcon("0deg"))
              }
            >
              <div className={`head items`}>
                <i>
                  <IoIosPeople />
                </i>
                <h4 className="m-0">Users</h4>
                <i
                  className="dropicon"
                  style={{ transform: `rotate(${genelogyDropdownIcon})` }}
                >
                  <BsChevronDown />
                </i>
              </div>
              <div className="dropdown" style={{ display: genelogyDropdown }}>
                <NavLink
                  to="all_users"
                  activeClassName="active"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (width < 800) {
                      setToggle("-260px");
                    }
                  }}
                >
                  <div className="items">
                    <i>
                      <GiLetterBomb />
                    </i>
                    <h4 className="m-0">All Users</h4>
                  </div>
                </NavLink>
              </div>
            </div>

            {/* <div
              className="dropdownitems"
              onClick={() =>
                withdrawalDropdown === "none"
                  ? (setwithdrawalDropdown("block"),
                    setwithdrawalDropdownIcon("180deg"))
                  : (setwithdrawalDropdown("none"),
                    setwithdrawalDropdownIcon("0deg"))
              }
            >
              <div className={`head items`}>
                <i>
                  <FaMoneyBillAlt />
                </i>
                <h4 className="m-0">Bank Reports</h4>
                <i
                  className="dropicon"
                  style={{ transform: `rotate(${withdrawalDropdownIcon})` }}
                >
                  <BsChevronDown />
                </i>
              </div>
              <div className="dropdown" style={{ display: withdrawalDropdown }}>
                <NavLink exact={true} to="bank" activeClassName="active">
                  <div className="items">
                    <i>
                      <BsBank2 />
                    </i>
                    <h4 className="m-0">Bank Details</h4>
                  </div>
                </NavLink>
                <NavLink to="select_upi" activeClassName="active">
                  <div className="items">
                    <i>
                      <IoQrCodeSharp />
                    </i>
                    <h4 className="m-0">UPI</h4>
                  </div>
                </NavLink>
              </div>
            </div> */}

            <div
              className="dropdownitems"
              onClick={() =>
                payoutReportDropdown === "none"
                  ? (setPayoutReportDropdown("block"),
                    setPayoutReportDropdownIcon("180deg"))
                  : (setPayoutReportDropdown("none"),
                    setPayoutReportDropdownIcon("0deg"))
              }
            >
              <div className={`head items`}>
                <i>
                  <AiOutlineFundProjectionScreen />
                </i>
                <h4 className="m-0">Funds</h4>
                <div
                  className="reminder"
                  style={{
                    backgroundColor:
                      dashboardData?.countPendingPaymentRequests === 0
                        ? "green"
                        : "red",
                  }}
                >
                  {dashboardData?.countPendingPaymentRequests}
                </div>
                <i
                  className="dropicon"
                  style={{ transform: `rotate(${payoutReportDropdownIcon})` }}
                >
                  <BsChevronDown />
                </i>
              </div>
              <div
                className="dropdown"
                style={{ display: payoutReportDropdown }}
              >
                <NavLink
                  to="fund_controls"
                  activeClassName="active"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (width < 800) {
                      setToggle("-260px");
                    }
                  }}
                >
                  <div className="items">
                    <i>
                      <RiFundsBoxFill />
                    </i>
                    <h4 className="m-0">Fund Request</h4>
                  </div>
                </NavLink>
                <NavLink
                  to="add_fund"
                  activeClassName="active"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (width < 800) {
                      setToggle("-260px");
                    }
                  }}
                >
                  <div className="items">
                    <i>
                      <RiRefund2Line />
                    </i>
                    <h4 className="m-0">Add Fund</h4>
                  </div>
                </NavLink>
                <NavLink
                  to="fund_history"
                  activeClassName="active"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (width < 800) {
                      setToggle("-260px");
                    }
                  }}
                >
                  <div className="items">
                    <i>
                      <GiLetterBomb />
                    </i>
                    <h4 className="m-0">Fund History</h4>
                  </div>
                </NavLink>
              </div>
            </div>

            {/* --------------------------------My Genelogy--------------------------------- */}

            {/*<div
              className="dropdownitems"
              onClick={() =>
                fundDropdown === "none"
                  ? (setfundDropdown("block"),
                    setfundDropdownIcon("180deg"))
                  : (setfundDropdown("none"),
                    setfundDropdownIcon("0deg"))
              }
            >
              <div className={`head items`}>
                <i>
                  <IoIosPeople />
                </i>
                <h4 className="m-0">Fund Request</h4>
                <i
                  className="dropicon"
                  style={{ transform: `rotate(${fundDropdownIcon})` }}
                >
                  <BsChevronDown />
                </i>
              </div>
              <div className="dropdown" style={{ display: fundDropdown }}>
                <NavLink to="pending_fund" activeClassName="active">
                  <div className="items">
                    <i>
                      <GiLetterBomb />
                    </i>
                    <h4 className="m-0">Pending</h4>
                  </div>
                </NavLink>
                <NavLink to="approved_fund" activeClassName="active">
                  <div className="items">
                    <i>
                      <GiLetterBomb />
                    </i>
                    <h4 className="m-0">Approved</h4>
                  </div>
                </NavLink>
                <NavLink to="rejected_fund" activeClassName="active">
                  <div className="items">
                    <i>
                      <GiLetterBomb />
                    </i>
                    <h4 className="m-0">Rejected</h4>
                  </div>
                </NavLink>
              </div>
            </div>*/}
            <NavLink
              to="withdraw_controls"
              activeClassName="active"
              onClick={(e) => {
                e.stopPropagation();
                if (width < 800) {
                  setToggle("-260px");
                }
              }}
            >
              <div className="items">
                <i>
                  <BiMoneyWithdraw />
                </i>
                <h4 className="m-0">Withdrawal</h4>
                <div
                  className="reminder"
                  style={{
                    backgroundColor:
                      dashboardData?.countPendingWithdrawals === 0
                        ? "green"
                        : "red",
                  }}
                >
                  {dashboardData?.countPendingWithdrawals}
                </div>
              </div>
            </NavLink>
            {/*<div
              className="dropdownitems"
              onClick={() =>
                withdrawalDropdown === "none"
                  ? (setwithdrawalDropdown("block"),
                    setwithdrawalDropdownIcon("180deg"))
                  : (setwithdrawalDropdown("none"),
                    setwithdrawalDropdownIcon("0deg"))
              }
            >
              <div className={`head items`}>
                <i>
                  <IoIosPeople />
                </i>
                <h4 className="m-0">Withdrawal</h4>
                <i
                  className="dropicon"
                  style={{ transform: `rotate(${withdrawalDropdownIcon})` }}
                >
                  <BsChevronDown />
                </i>
              </div>
              <div className="dropdown" style={{ display: withdrawalDropdown }}>
                <NavLink to="pending_withdrawal" activeClassName="active">
                  <div className="items">
                    <i>
                      <GiLetterBomb />
                    </i>
                    <h4 className="m-0">Pending Withdrawal</h4>
                  </div>
                </NavLink>
                <NavLink to="approved_withdrawal" activeClassName="active">
                  <div className="items">
                    <i>
                      <GiLetterBomb />
                    </i>
                    <h4 className="m-0">Approved Withdrawal</h4>
                  </div>
                </NavLink>
                <NavLink to="rejected_withdrawal" activeClassName="active">
                  <div className="items">
                    <i>
                      <GiLetterBomb />
                    </i>
                    <h4 className="m-0">Rejected Withdrawal</h4>
                  </div>
                </NavLink>
              </div>
            </div>*/}
            {/* --------------------------------------Payout Report--------------------------------------*/}
            <NavLink
              to="payout_controls"
              activeClassName="active"
              onClick={() => (width < 800 ? setToggle("-260px") : "")}
            >
              <div className="items">
                <i>
                  <FaMoneyCheckAlt />
                </i>
                <h4 className="m-0">Payout Report</h4>
              </div>
            </NavLink>

            {/*<div
              className="dropdownitems"
              onClick={() =>
                payoutReportDropdown === "none"
                  ? (setPayoutReportDropdown("block"),
                    setPayoutReportDropdownIcon("180deg"))
                  : (setPayoutReportDropdown("none"),
                    setPayoutReportDropdownIcon("0deg"))
              }
            >
              <div className={`head items`}>
                <i>
                  <FaMoneyCheckAlt />
                </i>
                <h4 className="m-0">Payout Report</h4>
                <i
                  className="dropicon"
                  style={{ transform: `rotate(${payoutReportDropdownIcon})` }}
                >
                  <BsChevronDown />
                </i>
              </div>
              <div
                className="dropdown"
                style={{ display: payoutReportDropdown }}
              >
                <NavLink to="roi_income">
                  <div className="items">
                    <i>
                      <GiLetterBomb />
                    </i>
                    <h4 className="m-0">Roi Income</h4>
                  </div>
                </NavLink>
                <NavLink to="level-income">
                  <div className="items">
                    <i>
                      <GiLetterBomb />
                    </i>
                    <h4 className="m-0">Direct Income</h4>
                  </div>
                </NavLink>
                <NavLink to="royalty_income">
                  <div className="items">
                    <i>
                      <GiLetterBomb />
                    </i>
                    <h4 className="m-0">Royalty Income</h4>
                  </div>
                </NavLink>
                <NavLink to="roilevel_income">
                  <div className="items">
                    <i>
                      <GiLetterBomb />
                    </i>
                    <h4 className="m-0">ROI Level Income</h4>
                  </div>
                </NavLink>
              </div>
            </div>*/}

            {/* -----------------------------------------------------------------------------------------------*/}
            <NavLink
              to="orders"
              activeClassName="active"
              onClick={() => (width < 800 ? setToggle("-260px") : "")}
            >
              <div className="items">
                <i>
                  <BsFillCartCheckFill />
                </i>
                <h4 className="m-0">Orders</h4>
              </div>
            </NavLink>

            <NavLink
              to="banner"
              activeClassName="active"
              onClick={() => (width < 800 ? setToggle("-260px") : "")}
            >
              <div className="items">
                <i>
                  <ImImages />
                </i>
                <h4 className="m-0">Banners</h4>
              </div>
            </NavLink>

            <div
              className="dropdownitems"
              onClick={() =>
                fundDropdown === "none"
                  ? (setfundDropdown("block"), setfundDropdownIcon("180deg"))
                  : (setfundDropdown("none"), setfundDropdownIcon("0deg"))
              }
            >
              <div className={`head items`}>
                <i>
                  <IoMdSettings />
                </i>
                <h4 className="m-0">Settings</h4>
                <i
                  className="dropicon"
                  style={{ transform: `rotate(${fundDropdownIcon})` }}
                >
                  <BsChevronDown />
                </i>
              </div>
              <div className="dropdown" style={{ display: fundDropdown }}>
                <NavLink
                  to="bank"
                  activeClassName="active"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (width < 800) {
                      setToggle("-260px");
                    }
                  }}
                >
                  <div className="items">
                    <i>
                      <BsBank2 />
                    </i>
                    <h4 className="m-0">Bank Setting</h4>
                  </div>
                </NavLink>
                <NavLink
                  to="select_upi"
                  activeClassName="active"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (width < 800) {
                      setToggle("-260px");
                    }
                  }}
                >
                  <div className="items">
                    <i>
                      <IoQrCodeSharp />
                    </i>
                    <h4 className="m-0">UPI Setting</h4>
                  </div>
                </NavLink>
                <NavLink
                  to="plan_setting"
                  activeClassName="active"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (width < 800) {
                      setToggle("-260px");
                    }
                  }}
                >
                  <div className="items">
                    <i>
                      <BsHddStackFill />
                    </i>
                    <h4 className="m-0">Plan Setting</h4>
                  </div>
                </NavLink>
              </div>
            </div>

            {/*<NavLink to="plan_setting" activeClassName="active">
              <div className="items">
                <i>
                  <IoMdSettings />
                </i>
                <h4 className="m-0">Settings</h4>
              </div>
            </NavLink>
             <NavLink to="Single_Leg_Goal" activeClassName="active">
              <div
                className="items"
              >
                <i>
                  <BsFillCartCheckFill />
                </i>
                <h4 className="m-0">Single Leg Goal</h4>
              </div>
            </NavLink>
            <NavLink to="rewards" activeClassName="active">
              <div
                className="items"
              >
                <i>
                  <BsFillCartCheckFill />
                </i>
                <h4 className="m-0">Rewards</h4>
              </div>
            </NavLink>
            */}

            <div className="items" onClick={Logout}>
              <i>
                <IoIosLogOut />
              </i>
              <h4 className="m-0">Logout</h4>
            </div>
          </section>
        </div>
        <div
          id=""
          className="p-0 page-content"
          style={{
            background: "#F4F7FC",
            minHeight: "100vh",
            marginLeft:
              width > 900 ? (toggle === "-260px" ? "0px" : "260px") : "0px",
          }}
        >
          <div className="navMain">
            <div className="topNavbar">
              <i
                onClick={() =>
                  toggle === "-260px" ? setToggle("0") : setToggle("-260px")
                }
                style={{ cursor: "pointer" }}
              >
                <GiHamburgerMenu />
              </i>
              <div
              // style={{ marginRight: toggle === "-260px" ? "0px" : "260px" }}
              ></div>
            </div>
          </div>

          <div style={{ marginTop: "80px" }}>{<NavPages />}</div>
        </div>
      </Container>
    </React.Fragment>
  );
};
// const Sidebar = withRouter(Side);
export default Sidebar;
