import React, { useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import "./With.css";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { approveWithdrawalRequest, WithdrawalRequest, rejectWithdrawalRequest } from "../../Actions/adminActions";
import moment from "moment";

const WithdrawDetails = () => {
    const location = useLocation();
    const { withdrawal } = location.state || {};
    const dispatch = useDispatch();
    const [rejectionReason, setRejectionReason] = useState("");

    const handleApprove = async (tx_Id) => {
        try {
          await dispatch(approveWithdrawalRequest([tx_Id]));
        //   dispatch(
        //     WithdrawalRequest({
        //       source:'withdrawal',
        //       page: 1,
        //       limit: 20,
        //       status: "0",
        //     })
        //   );
        } catch(e) {
          console.log(e);
        }
      }
      const handleReject = async (tx_Id) => {
        try {
          await dispatch(rejectWithdrawalRequest([tx_Id],rejectionReason));
          setRejectionReason("");
        //   dispatch(
        //     WithdrawalRequest({
        //       source:'withdrawal',
        //       page:1,
        //       limit:20,
        //       status:"0",
        //     })
        //   );
        } catch (e) {
          console.log(e);
        }
      }

  return (
    <React.Fragment>
        <Container>
            <h3>Withdrawal Details</h3>
            <hr></hr>
           
            <Row id="directTabRow">
                <Col xs="12" sm="12" md="8" lg="8" >
                <div className="table-responsive">
                    <table className="detailTable">
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <td>:</td>
                                <td>{withdrawal?.name}</td>
                            </tr>
                            <tr>
                                <th>Username</th>
                                <td>:</td>
                                <td>{withdrawal?.username}</td>
                            </tr>
                            <tr>
                                <th>Amount</th>
                                <td>:</td>
                                <td>{withdrawal?.amount}</td>
                            </tr>
                            <tr>
                                <th>Account Details</th>
                                <td>:</td>
                                <td>{withdrawal?.account && Object.entries(withdrawal?.account)?.map(([key, value])=>(
                <div className="keyValue d-flex align-items-center gap-2">
                  <p>{key} :</p>
                  <p>{value}</p>
                </div> 
              ))}
                                </td>
                            </tr>
                            <tr>
                                <th>Status</th>
                                <td>:</td>
                                <td>{withdrawal?.status===0?"Pending":"Approved"}</td>
                            </tr>
                            <tr>
                                <th>Date</th>
                                <td>:</td>
                                <td>{moment(withdrawal?.createdAt).format('DD-MM-YYYY   &   HH:mm')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
               </Col>
               <Col xs="12" sm="12" md="4" lg="4">
               <form>
                <div className="form-group">
                    <label>Reason (Give Reason of cancellation)</label>
                    <textarea name="rejectionReason"  value={rejectionReason} className="form-control"></textarea>
                    <small className="text-muted"></small>
                </div>
                <div className="form-group">
                <button className="action-btn" onClick={() => handleApprove(withdrawal.tx_Id)}>
                    Approve
                </button>
                <button className="action-btn" onClick={() => handleReject(withdrawal.tx_Id)}>
                    Reject
                </button>
                </div>
               </form>
               </Col>
            </Row>
        </Container>
    </React.Fragment>
  )
}

export default WithdrawDetails
