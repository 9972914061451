import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import apiCall from "./../../API/API";
import { toast, ToastContainer } from "react-toastify";
import RoiIncome from "./RoiIncome";
import RoiLevelIncome from "./ROILevelIncome";
import RoyaltyIncome from "./RoyaltyIncome";
import LevelIncome from "./LevelIncome";
import "bootstrap/dist/css/bootstrap.min.css";
import "./PayoutControls.css";
import { Container, Row, Col, NavLink, Button } from "react-bootstrap";

const PayoutControls = () => {
  const { paymentRequests } = useSelector((state) => state.admin);
  const [roiIncome, setRoiIncome] = useState("");
  const [roiLevelIncome, setRoiLevelIncome] = useState("");
  const [royaltyIncome, setRoyaltyIncome] = useState("");
  const [directIncome, setDirectIncome] = useState("");
  const [totalIncome, setTotalIncome] = useState("");
  const [todayTotalIncome, setTodayTotalIncome] = useState("");
  const [todayRoiIncome, setTodayRoiIncome] = useState("");
  const [todayRoiLevelIncome, setTodayRoiLevelIncome] = useState("");
  const [todayRoyaltyIncome, setTodayRoyaltyIncome] = useState("");
  const [todayDirectIncome, setTodayDirectIncome] = useState("");

  const hasFetchedData = useRef(false);

  const fetchIncomes = async () => {
    const { data, error } = await apiCall("GET", "/total_payout");
    if (error) {
      toast.error(error.message || "An error occurred");
    } else {
      setRoiIncome(data.roi_income);
      setRoiLevelIncome(data.roi_level_income);
      setRoyaltyIncome(data.royality_income);
      setDirectIncome(data.direct_income);
      setTotalIncome(data.total_incomes);
      setTodayTotalIncome(data.today_total_incomes);
      setTodayRoiIncome(data.today_roi_income);
      setTodayRoiLevelIncome(data.today_roi_level_income);
      setTodayRoyaltyIncome(data.today_royality_income);
      setTodayDirectIncome(data.today_direct_income);
    }
  };

  useEffect(() => {
    if (!hasFetchedData.current) {
      fetchIncomes();
      hasFetchedData.current = true;
    }
  }, []);

  const bonuses = [
    {
      title: "ROI Income",
      value: "roi_income",
      total: roiIncome,
      today: todayRoiIncome,
    },
    {
      title: "ROI Level Income",
      value: "roilevel_income",
      total: roiLevelIncome,
      today: todayRoiLevelIncome,
    },
    {
      title: "Royalty Income",
      value: "royalty_income",
      total: royaltyIncome,
      today: todayRoyaltyIncome,
    },
    {
      title: "Direct Income",
      value: "level_income",
      total: directIncome,
      today: todayDirectIncome,
    },
  ];
  const [activeTab, setActiveTab] = useState(bonuses[0]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    const activeTab =
      bonuses.find((bonus) => bonus.value === tab) || bonuses[0];
    setActiveTab(activeTab);
    console.log("activeTab", activeTab);
  }, [location.search]);

  function handleTabClick(value) {
    setActiveTab(value);
    navigate(`/dashboard/${value.value}`);
  }
  return (
    <React.Fragment>
      <Container>
        <div className="list-container">
          <ul className="list-group">
            <Row md="12" className="justify-content-center">
              <Col md="5" className="justify-center ">
                <div className="list-group-item1">
                  <h3>Total Income</h3>
                  <Row>
                    <Col>
                      <div className="list1">
                        <h4>{totalIncome || 0}</h4>
                        <p>Total</p>
                      </div>
                    </Col>
                    <Col>
                      <div className="list1">
                        <h4>{todayTotalIncome || 0}</h4>
                        <p>Today</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            {bonuses
              .filter((x) => x.total !== 0)
              .map((x, i) => (
                <Row md="12" className="justify-content-center">
                  <Col md="5" className="justify-center">
                    <NavLink
                      key={i}
                      className={`list-group-item ${
                        x.value === activeTab.value ? "active" : ""
                      }`}
                      onClick={() => handleTabClick(x)}
                      style={{ cursor: "pointer" }}
                    >
                      <h3>{x.title}</h3>
                      <Row>
                        <Col>
                          <div className="list">
                            <h4>{x.total || 0}</h4>
                            <p>Total</p>
                          </div>
                        </Col>
                        <Col>
                          <div className="list">
                            <h4>{x.today || 0}</h4>
                            <p>Today</p>
                          </div>
                        </Col>
                      </Row>
                    </NavLink>
                  </Col>
                </Row>
              ))}
          </ul>
        </div>
        <ToastContainer className="toast-container" />
      </Container>
    </React.Fragment>
  );
};

export default PayoutControls;
