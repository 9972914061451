import React, { useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import "./AddFund.css";
import apiCall from "./../../API/API";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddFunds = () => {
  const [userName, setUserName] = useState("");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("authToken");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        },
      };
      const { data, error } = await apiCall(
        "post",
        "/add_fund",
        {
          amount: amount,
          username: userName,
          wallet_name: "main_wallet",
        },
        config
      );

      if (data) {
        setUserName("");
        setAmount("");
      } else if (error) {
        toast.error(error.message || "An error occurred");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Container className="mt-4">
        <section id="add-funds-section">
          <div className="d-flex justify-content-center">
            <h2>Add Funds</h2>
          </div>
          <section id="formContent">
            <div className="form">
              <Row>
                <Col lg={6} style={{ margin: "auto" }}>
                  <form onSubmit={handleSubmit}>
                    <div id="formFields">
                      <div className="mb-3">
                        <label htmlFor="userName">User Name:</label>
                        <input
                          type="text"
                          id="userName"
                          className="form-control"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                          placeholder="Enter your Username"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="amount">Amount:</label>
                        <input
                          type="number"
                          id="amount"
                          className="form-control"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          placeholder="Enter Amount"
                          required
                        />
                      </div>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={loading}
                      >
                        {loading ? "Submitting..." : "Submit"}
                      </Button>
                      <Button
                        variant="secondary"
                        className="ms-2"
                        onClick={() => {
                          setUserName("");
                          setAmount("");
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </Col>
              </Row>
            </div>
          </section>
        </section>
        <ToastContainer className="toast-container" />
      </Container>
    </React.Fragment>
  );
};

export default AddFunds;
