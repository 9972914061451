// src/actions/adminActions.js
import createApiAction from './apiActions';
import * as actionTypes from './actionTypes';

export const login = (credentials) => createApiAction({
  method: 'post',
  endpoint: '/login',
  data: credentials,
  requestType: actionTypes.LOGIN_REQUEST,
  successType: actionTypes.LOGIN_SUCCESS,
  failureType: actionTypes.LOGIN_FAILURE,
  showToast:true
});

export const fetchPaymentOptions = () => createApiAction({
  method: 'get',
  endpoint: '/save_payment_option',
  requestType: actionTypes.FETCH_PAYMENT_OPTIONS_REQUEST,
  successType: actionTypes.FETCH_PAYMENT_OPTIONS_SUCCESS,
  failureType: actionTypes.FETCH_PAYMENT_OPTIONS_FAILURE,
  showToast:false
});

export const fetchAllUsers = (queryParams) => createApiAction({
  method: 'get',
  endpoint: `/get_all_team?${new URLSearchParams(queryParams).toString()}`,
  requestType: actionTypes.FETCH_ALL_USERS_REQUESTS_REQUEST,
  successType: actionTypes.FETCH_ALL_USERS_REQUESTS_SUCCESS,
  failureType: actionTypes.FETCH_ALL_USERS_REQUESTS_FAILURE,
  showToast:false
});

export const fetchAllOrder = (queryParams) => createApiAction({
  method: 'get',
  endpoint: `/get_all_orders?${new URLSearchParams(queryParams).toString()}`,
  requestType: actionTypes.FETCH_ALL_ORDER_REQUEST,
  successType: actionTypes.FETCH_ALL_ORDER_SUCCESS,
  failureType: actionTypes.FETCH_ALL_ORDER_FAILURE,
  showToast:false
});

export const fetchAllTransaction = (queryParams) => createApiAction({
  method: 'get',
  endpoint: `/get-payment-transaction?${new URLSearchParams(queryParams).toString()}`,
  requestType: actionTypes.FETCH_ALL_PAYMENT_REQUESTS_REQUEST,
  successType: actionTypes.FETCH_ALL_PAYMENT_REQUESTS_SUCCESS,
  failureType: actionTypes.FETCH_ALL_PAYMENT_REQUESTS_FAILURE,
  showToast:false
});

export const WithdrawalRequest = (queryParams) => createApiAction({
  method: 'get',
  endpoint: `/all-withdrawal-request?${new URLSearchParams(queryParams).toString()}`,
  requestType: actionTypes.FETCH_ALL_WITHDRAW_REQUEST_REQUEST,
  successType: actionTypes.FETCH_ALL_WITHDRAW_REQUEST_SUCCESS,
  failureType: actionTypes.FETCH_ALL_WITHDRAW_REQUEST_FAILURE,
  showToast:false
});

export const approvePaymentRequest = (requestIds) => createApiAction({
  method: 'post',
  endpoint: '/approve-payment-request',
  data: { requestIds },
  requestType: actionTypes.APPROVE_PAYMENT_REQUEST_REQUEST,
  successType: actionTypes.APPROVE_PAYMENT_REQUEST_SUCCESS,
  failureType: actionTypes.APPROVE_PAYMENT_REQUEST_FAILURE,
  showToast:true
});
export const approveWithdrawalRequest = (requestIds) => createApiAction({
  method: 'post',
  endpoint: '/approve-withdrawal-request',
  data: { requestIds },
  requestType: actionTypes.APPROVE_PAYMENT_REQUEST_REQUEST,
  successType: actionTypes.APPROVE_PAYMENT_REQUEST_SUCCESS,
  failureType: actionTypes.APPROVE_PAYMENT_REQUEST_FAILURE,
  showToast:true
});

export const rejectPaymentRequest = (requestIds,reason) => createApiAction({
  method: 'post',
  endpoint: '/reject-payment-request',
  data: { requestIds,reason },
  requestType: actionTypes.REJECT_PAYMENT_REQUEST_REQUEST,
  successType: actionTypes.REJECT_PAYMENT_REQUEST_SUCCESS,
  failureType: actionTypes.REJECT_PAYMENT_REQUEST_FAILURE,
  showToast:true
});
export const rejectWithdrawalRequest = (requestIds,reason) => createApiAction({
  method: 'post',
  endpoint: '/reject-withdrawal-request',
  data: { requestIds,reason },
  requestType: actionTypes.REJECT_WITHDRAW_REQUEST_REQUEST,
  successType: actionTypes.REJECT_WITHDRAW_REQUEST_SUCCESS,
  failureType: actionTypes.REJECT_WITHDRAW_REQUEST_FAILURE,
  showToast:true
});

export const fetchDashboardData = () => createApiAction({
  method: 'get',
  endpoint: '/get-dashboard-data',
  requestType: actionTypes.FETCH_DASHBOARD_DATA_REQUEST,
  successType: actionTypes.FETCH_DASHBOARD_DATA_SUCCESS,
  failureType: actionTypes.FETCH_DASHBOARD_DATA_FAILURE,
  showToast:false
});

export const fetchPaymentTransactions = () => createApiAction({
  method: 'get',
  endpoint: '/get-payment-transaction',
  requestType: actionTypes.FETCH_PAYMENT_TRANSACTIONS_REQUEST,
  successType: actionTypes.FETCH_PAYMENT_TRANSACTIONS_SUCCESS,
  failureType: actionTypes.FETCH_PAYMENT_TRANSACTIONS_FAILURE,
  showToast:false
});
