import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import apiCall from './../../API/API'; 
import "./Upi.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import AlertMessage from '../../Component/AlertMessage/AlertMessage';
import { fetchAllTransaction } from '../../Actions/adminActions';
import { BsCheckCircle } from 'react-icons/bs';
import { BiErrorCircle } from 'react-icons/bi';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const BankDetails = () => {
  const [bankName, setBankName] = useState("");
  const [ifsc, setIfscCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [holder, setAccountHolderName] = useState("");
  const [ac_type, setAccountType] = useState("");
  const [branch, setBankBranch] = useState("");
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(''); 
  const [success, setSuccess] = useState('null');
  const [bankData, setBankData] = useState([]);
  const [defaultAccountNumber, setDefaultAccountNumber] = useState(null);
  const { paymentRequests } = useSelector((state) => state.admin);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchBankData = async () => {
    const { data, error } = await apiCall('GET', '/get-payment-method');
    if (error) {
      toast.error(error.message || "An error occurred");
    } else {
      setBankData(data?.activeOptions?.manual?.bank);
      const defaultBank = data?.activeOptions?.manual?.bank?.find(bank => bank.isDefault);
      setDefaultAccountNumber(defaultBank?.accountNumber || null);
    }
  };

  useEffect(() => {
    fetchBankData();
  }, []);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { data, error } = await apiCall('POST', '/add-bank-details', { bankName, ifsc, accountNumber, holder, ac_type, branch });
    if (error) {
      toast.error(error.message || "An error occurred");
    } else {
      toast.success(data.message || "Bank details submitted successfully");
      setBankName('');
      setIfscCode('');
      setAccountNumber('');
      setAccountHolderName('');
      setAccountType('');
      setBankBranch('');
      fetchBankData();
    }

    setLoading(false);
  };

  const handleDelete = async (accountNumber) => {
    console.log('account number',accountNumber);
    const { data, error } = await apiCall('POST', `/delete-bank-details`, { accountNumber });
    if (error) {
      toast.error(error.message || "An error occurred");
    } else {
      toast.success(data.message || "Bank detail deleted successfully");
      fetchBankData();
    }
  };

  const handleDefault = async (accountNumber) => {
    console.log('account number',accountNumber);
    const { data, error } = await apiCall('POST', `/set-default-bank-details`, { accountNumber });
    if (error) {
      toast.error(error.message || "An error occurred");
    } else {
      toast.success(data.message || "Bank detail set default successfully");
      fetchBankData();
    }
  };

  return (
    <React.Fragment>
      <Container className="mt-4">
        <section id="bankForm">
          <div className="d-flex justify-content-center">
            <h2>Bank Details Form</h2>
          </div>
          <section id="formContent">
            <div className="form">
              <Row>
                <Col lg={6} style={{ margin: 'auto' }}>
                  <form onSubmit={handleSubmit} className="registerForm">
                    <div id="formFields">
                      <div className="mb-3">
                        <label htmlFor="bankName">Bank Name</label>
                        <input
                          type="text"
                          id="bankName"
                          className="form-control"
                          value={bankName}
                          placeholder="Enter your Bank name"
                          onChange={(e) => setBankName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="ifscCode">IFSC Code</label>
                        <input
                          type="text"
                          id="ifscCode"
                          className="form-control"
                          value={ ifsc}
                          placeholder="Enter your IFSC Code"
                          onChange={(e) => setIfscCode(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="accountNumber">Account Number</label>
                        <input
                          type="text"
                          id="accountNumber"
                          className="form-control"
                          value={accountNumber}
                          placeholder="Enter Account Number"
                          onChange={(e) => setAccountNumber(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="accountHolderName">Account Holder Name</label>
                        <input
                          type="text"
                          id="accountHolderName"
                          className="form-control"
                          value={holder}
                          placeholder="Enter Account Holder Name"
                          onChange={(e) => setAccountHolderName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="accountType">Account Type</label>
                        <input
                          type="text"
                          id="accountType"
                          className="form-control"
                          value={ac_type}
                          placeholder="Enter Account Type"
                          onChange={(e) => setAccountType(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="bankBranch">Bank Branch</label>
                        <input
                          type="text"
                          id="bankBranch"
                          className="form-control"
                          value={branch}
                          placeholder="Enter Bank Branch"
                          onChange={(e) => setBankBranch(e.target.value)}
                          required
                        />
                      </div>
                      <Button type="submit" variant="primary" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit'}
                      </Button>
                      <Button
                        variant="secondary"
                        className="ms-2"
                        onClick={() => {
                          setBankName('');
                          setIfscCode('');
                          setAccountNumber('');
                          setAccountHolderName('');
                          setAccountType('');
                          setBankBranch('');
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </Col>
              </Row>
            </div>
          </section>
        </section>
        {/* {success === 'true' ? (
          <AlertMessage
            msg="Bank information submitted successfully"
            icon={<BsCheckCircle />}
          />
        ) : success === 'false' ? (
          <AlertMessage
            msg={error || 'Submission failed'}
            icon={<BiErrorCircle />}
          />
        ) : ''} */}
        
        <div id="directIncomeDiv">
        <Row id="directTableRow" className="text-center">
                        <Col md="12" className="p-0">
                            <div style={{ width: "100%" }}>
                                <table>
                                    <tr>
                                    <th>S No.</th>
                                    <th>Bank Name</th>
                                    <th>IFSC Code</th>
                                    <th>Account Number</th>
                                    <th>Account Holder Name</th>
                                    <th>Account Type</th>
                                    <th>Bank Branch</th>
                                    <th>Delete Bank</th>
                                    <th>Set Default</th>                          
                                    </tr>
                                    {Array.isArray(bankData) && bankData?.map((x, i) => (
                      <tr key={i}>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-center">{x?.bankName}</td>
                        <td className="text-center">{x?.ifsc}</td>
                        <td className="text-center">{x?.accountNumber}</td>
                        <td className="text-center">{x?.holder}</td>
                        <td className="text-center">{x?.ac_type}</td>
                        <td className="text-center">{x?.branch}</td>
                        <td className="text-center">
                          <button 
                            className="block-btn"
                            onClick={() => handleDelete(x?.accountNumber)}>
                            Delete Bank
                          </button>
                        </td>
                        <td className="text-center">
                          <button
                            className={`block-btn ${defaultAccountNumber === x?.accountNumber ? 'default-btn' : ''}`}
                            onClick={() => handleDefault(x?.accountNumber)}
                            disabled={defaultAccountNumber === x?.accountNumber}>
                            {defaultAccountNumber === x?.accountNumber ? 'Default' : 'Set Default'}
                          </button>
                        </td>
                      </tr>
                    ))}
                                </table>
                            </div>
                        </Col>
                    </Row>
        </div>
        <ToastContainer className="toast-container" />
      </Container>
    </React.Fragment>
  );
};

export default BankDetails;
