import React, { useState, useEffect } from 'react';
import './AlertMessage.css';

const AlertMessage = (props) => {
    const [visible, setVisible] = useState(true);
    const { color = "#0b4a0b", bgcolor = "#C2EAC2", hrcolor = "#5cb35cf0", icon, msg } = props;

    useEffect(() => {
        // Hide the alert after 5 seconds
        const timer = setTimeout(() => {
            setVisible(false);
        }, 5000); // Adjust time as needed

        return () => clearTimeout(timer);
    }, []);

    if (!visible) return null;

    return (
        <div className="alertBody" style={{ background: bgcolor, color: color }}>
            <div className="alertContent">
                <i className="alertIcon">{icon}</i>
                <p className="alertText">{msg}</p>
                <span className="alertClose" onClick={() => setVisible(false)}>✕</span>
            </div>
            <hr style={{ backgroundColor: hrcolor }} className="alertTimeline" />
        </div>
    );
};

export default AlertMessage;









/*import React from 'react'
import "./AlertMessage.css"
const AlertMessage = (props) => {
    const [visible, setVisible] = useState(true);
    var color = "#0b4a0b";
    var bgcolor = "#C2EAC2";
    var hrcolor = "#5cb35cf0";
    if (props.bgcolor) { bgcolor = props.bgcolor }
    if (props.color) { color = props.color }
    if (props.hrcolor) { hrcolor = props.hrcolor }
    return (
        <div className="alertBody" style={{ background: bgcolor, color: color }}>
            <div><i>{props.icon}</i>
                <p>{props.msg}</p></div>
            <hr style={{ backgroundColor: hrcolor }}></hr>
        </div>
    )
}

export default AlertMessage*/