// src/actions/actionTypes.js
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const FETCH_PAYMENT_OPTIONS_REQUEST = 'FETCH_PAYMENT_OPTIONS_REQUEST';
export const FETCH_PAYMENT_OPTIONS_SUCCESS = 'FETCH_PAYMENT_OPTIONS_SUCCESS';
export const FETCH_PAYMENT_OPTIONS_FAILURE = 'FETCH_PAYMENT_OPTIONS_FAILURE';

export const FETCH_ALL_USERS_REQUESTS_REQUEST = 'FETCH_ALL_USERS_REQUESTS_REQUEST';
export const FETCH_ALL_USERS_REQUESTS_SUCCESS = 'FETCH_ALL_USERS_REQUESTS_SUCCESS';
export const FETCH_ALL_USERS_REQUESTS_FAILURE = 'FETCH_ALL_USERS_REQUESTS_FAILURE';

export const FETCH_ALL_WITHDRAW_REQUEST_REQUEST = 'FETCH_ALL_WITHDRAW_REQUEST_REQUEST';
export const FETCH_ALL_WITHDRAW_REQUEST_SUCCESS = 'FETCH_ALL_WITHDRAW_REQUEST_SUCCESS';
export const FETCH_ALL_WITHDRAW_REQUEST_FAILURE = 'FETCH_ALL_WITHDRAW_REQUEST_FAILURE';

export const FETCH_ALL_PAYMENT_REQUESTS_REQUEST = 'FETCH_ALL_PAYMENT_REQUESTS_REQUEST';
export const FETCH_ALL_PAYMENT_REQUESTS_SUCCESS = 'FETCH_ALL_PAYMENT_REQUESTS_SUCCESS';
export const FETCH_ALL_PAYMENT_REQUESTS_FAILURE = 'FETCH_ALL_PAYMENT_REQUESTS_FAILURE';

export const FETCH_ALL_ORDER_REQUEST = 'FETCH_ALL_ORDER_REQUEST';
export const FETCH_ALL_ORDER_SUCCESS = 'FETCH_ALL_ORDER_SUCCESS';
export const FETCH_ALL_ORDER_FAILURE = 'FETCH_ALL_ORDER_FAILURE';

export const APPROVE_PAYMENT_REQUEST_REQUEST = 'APPROVE_PAYMENT_REQUEST_REQUEST';
export const APPROVE_PAYMENT_REQUEST_SUCCESS = 'APPROVE_PAYMENT_REQUEST_SUCCESS';
export const APPROVE_PAYMENT_REQUEST_FAILURE = 'APPROVE_PAYMENT_REQUEST_FAILURE';

export const APPROVE_WITHDRAW_REQUEST_REQUEST = 'APPROVE_WITHDRAW_REQUEST_REQUEST';
export const APPROVE_WITHDRAW_REQUEST_SUCCESS = 'APPROVE_WITHDRAW_REQUEST_SUCCESS';
export const APPROVE_WITHDRAW_REQUEST_FAILURE = 'APPROVE_WITHDRAW_REQUEST_FAILURE';

export const REJECT_WITHDRAW_REQUEST_REQUEST = 'REJECT_WITHDRAW_REQUEST_REQUEST';
export const REJECT_WITHDRAW_REQUEST_SUCCESS = 'REJECT_WITHDRAW_REQUEST_SUCCESS';
export const REJECT_WITHDRAW_REQUEST_FAILURE = 'REJECT_WITHDRAW_REQUEST_FAILURE';

export const REJECT_PAYMENT_REQUEST_REQUEST = 'REJECT_PAYMENT_REQUEST_REQUEST';
export const REJECT_PAYMENT_REQUEST_SUCCESS = 'REJECT_PAYMENT_REQUEST_SUCCESS';
export const REJECT_PAYMENT_REQUEST_FAILURE = 'REJECT_PAYMENT_REQUEST_FAILURE';

export const FETCH_DASHBOARD_DATA_REQUEST = 'FETCH_DASHBOARD_DATA_REQUEST';
export const FETCH_DASHBOARD_DATA_SUCCESS = 'FETCH_DASHBOARD_DATA_SUCCESS';
export const FETCH_DASHBOARD_DATA_FAILURE = 'FETCH_DASHBOARD_DATA_FAILURE';

export const FETCH_PAYMENT_TRANSACTIONS_REQUEST = 'FETCH_PAYMENT_TRANSACTIONS_REQUEST';
export const FETCH_PAYMENT_TRANSACTIONS_SUCCESS = 'FETCH_PAYMENT_TRANSACTIONS_SUCCESS';
export const FETCH_PAYMENT_TRANSACTIONS_FAILURE = 'FETCH_PAYMENT_TRANSACTIONS_FAILURE';
