import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import "./Banner.css";
import { FiUploadCloud } from "react-icons/fi";
import { MdOutlineModeEdit } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";
import { Container, Row, Col } from "react-bootstrap";
import imageCompression from "browser-image-compression";
import { useNavigate } from "react-router-dom";
import apiCall from "../../API/API";
import moment from "moment";
import { HiDownload } from "react-icons/hi";
import Popup from "reactjs-popup";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { FaExpandAlt } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
const Banner = () => {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState();
  const [previews, setPreviews] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [selectedDescription, setSelectedDescription] = useState(null);
  const navigate = useNavigate();
  const onDrop = async (acceptedFiles) => {
    // Compress images
    const compressedFiles = await Promise.all(
      acceptedFiles.map(async (file) => {
        const options = {
          maxSizeMB: 1, // Maximum file size in MB
          maxWidthOrHeight: 1024, // Maximum width or height in pixels
          useWebWorker: true,
        };
        try {
          return await imageCompression(file, options);
        } catch (err) {
          console.error("Error compressing image:", err);
          return file;
        }
      })
    );

    setFiles(compressedFiles);
    setPreviews(compressedFiles.map((file) => URL.createObjectURL(file)));
  };

  const removeImage = (index) => {
    const newFiles = [...files];
    const newPreviews = [...previews];
    newFiles.splice(index, 1);
    newPreviews.splice(index, 1);
    setFiles(newFiles);
    setPreviews(newPreviews);
  };

  const uploadFiles = async () => {
    console.log("title", title);

    if (title?.length > 0) {
      setUploading(true);
      setError(null);

      const formData = new FormData();
      files.forEach((file) => {
        console.log("file", file);
        formData.append("document", file);
      });

      formData.append("title", title);
      console.log("dddddddddddddddd", formData);
      try {
        const response = await apiCall("POST", "/upload-banner", formData);
        if (response?.error) {
          toast.error(response.error.message || "An error occurred");
        } else {
          setFiles([]);
          setPreviews([]);
          setTitle("");
          toast.success(
            response.data?.message || "Files uploaded successfully"
          );
          fetchBanner();
        }
      } catch (error) {
        console.error("Upload error:", error);
        setError("Failed to upload files. Please try again.");
      } finally {
        setUploading(false);
      }
    } else {
      toast.error("Invalid Data");
    }
  };

  const handleCloseModal = () => {
    setSelectedDescription(null);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const fetchBanner = async () => {
    try {
      const { data, error } = await apiCall("GET", "/get-banners");
      if (error) {
        toast.error(error.message || "An error occurred");
      } else {
        setBannerData(data);
      }
    } catch (error) {
      toast.error(error.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchBanner();
  }, []);

  const handleDelete = async (banner_id) => {
    console.log("id", banner_id);
    const { data, error } = await apiCall("POST", `/delete-banner`, {
      banner_id,
    });
    if (error) {
      // toast.error(error.message || "An error occurred");
    } else {
      toast.success(data.message || "Banner deleted successfully");
      fetchBanner();
    }
  };

  return (
    <React.Fragment>
      <Container>
        <h2>Banner</h2>
        <div id="directIncome">
          <Row className="mt-1">
            <Col md="12" className="mb-6 mb-md-0">
              <div className="input1">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Enter Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {/* <i id="myProfileInputFieldIcon">
                  <MdOutlineModeEdit />
                </i> */}
              </div>
            </Col>
          </Row>
          <div className="file-upload-container">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>Drop your files here</p>
            </div>
            <button
              className="upload-button"
              onClick={uploadFiles}
              disabled={uploading || files.length === 0}
            >
              <i>
                <FiUploadCloud />
              </i>
              {uploading ? "Uploading..." : "Upload Files"}
            </button>
            {error && <p className="error-message">{error}</p>}
            <div className="previews">
              {previews.map((preview, index) => (
                <div key={index} className="preview">
                  <img src={preview} alt={`Preview ${index}`} />
                  <button
                    className="remove-button"
                    onClick={() => removeImage(index)}
                  >
                    <RiCloseCircleLine />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div id="directIncomeDiv">
          <Row id="directTableRow" className="text-center">
            <Col md="12" className="p-0">
              <div style={{ width: "100%" }}>
                <table>
                  <tr>
                    <th>S No.</th>
                    <th>Image</th>
                    <th>Date</th>
                    <th>Delete Banner</th>
                    {/* <th>Set Default</th> */}
                  </tr>
                  {Array.isArray(bannerData?.banners) &&
                    bannerData?.banners?.map((x, i) => {
                      return (
                        <tr>
                          <td className="text-center">{i + 1}</td>
                          <td>
                            {" "}
                            <img
                              src={x?.document}
                              alt="Banner"
                              style={{
                                maxWidth: "100px",
                                maxHeight: "100px",
                              }}
                            />
                            <Popup
                              trigger={
                                <button className="block">
                                  <FaExpandAlt />
                                </button>
                              }
                              modal
                              closeOnDocumentClick
                            >
                              {(close) => (
                                <div className="popup-content">
                                  <button className="close-btn" onClick={close}>
                                    &times;
                                  </button>
                                  <img
                                    src={x?.document}
                                    alt="Banner"
                                    className="popImg"
                                  />
                                  {/* <button
                                  className="download-btn"
                                  onClick={() => {
                                    const link = document.createElement("a");
                                    link.href = x?.image;
                                    link.setAttribute("download", "news.jpg"); // Setting download attribute
                                    document.body.appendChild(link); // Append link to the body
                                    link.click(); // Trigger click to start download
                                    document.body.removeChild(link); // Remove link after download
                                  }}
                                >
                                  <HiDownload />
                                </button> */}
                                </div>
                              )}
                            </Popup>
                          </td>
                          <td>
                            {moment(x?.createdAt).format(
                              "DD-MM-YYYY   &   HH:mm"
                            )}
                          </td>
                          <td className="text-center">
                            <button
                              className="bin-btn"
                              onClick={() => handleDelete(x?.banner_id)}
                            >
                              <FaTrashAlt />
                            </button>
                          </td>
                          {/* <td className="text-center">
                          <button
                              className={`block-btn ${
                                defaultUpiId === x?.upiId ? "default-btn" : ""
                              }`}
                              onClick={() => handleDefault(x?.upiId)}
                              disabled={defaultUpiId === x?.upiId}
                            >
                              {defaultUpiId === x?.upiId
                                ? "Default"
                                : "Set Default"}
                            </button>
                        </td> */}
                        </tr>
                      );
                    })}
                </table>
              </div>
            </Col>
          </Row>
        </div>
        <ToastContainer className="toast-container" />
      </Container>
    </React.Fragment>
  );
};

export default Banner;

// import React from "react";
// import { Container, Row, Column } from "react-bootstrap";

// const Banner = () => {
//   return (
//     <React.Fragment>
//       <Container></Container>
//     </React.Fragment>
//   );
// };

// export default Banner;
