import Logo from "./../Images/logoI.svg";
import LogoIcon from "./../Images/logoI.svg";
import { toast } from "react-toastify";

const root = document.documentElement;
const rootStyles = getComputedStyle(root);
const rootColor = rootStyles.getPropertyValue("--colorPrimary");
const colorSec = rootStyles.getPropertyValue("--colorSuccess");
export const Data = {
  isDebug: true,
  colorPrimary: rootColor,
  colorSuccess: colorSec,
  projectName: "Admin",
  website: "",
  logo: Logo,
  logoIcon: LogoIcon,
};
export const MyDelay = (delayInms) => {
  return new Promise((resolve) => setTimeout(resolve, delayInms));
};
export const toastSuccess = (msg) => toast.success(msg);
export const toastFailed = (msg) => toast.error(msg);
