// src/components/Login.js
import React, { useEffect, useState } from 'react';
import "./Login.css";
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../Actions/adminActions';
import { Container } from "react-bootstrap";
import { AiOutlineUser } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { Data } from "../../Common/Data.js";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';

const Login = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [passwordVisility, setPasswordVisiblity] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const { loading, error, user } = useSelector((state) => state.admin);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(credentials))

  };
  useEffect(() => {
  if (user) {
    navigate("/dashboard");
  }
  }, [ user ])
  

  return (
    <>
      <Container id="logincontainer">
        <div className="loginContent">
          <div className="loginLogo">
            <img src={Data.logo} alt="logo.png" />
          </div>
          <div className="loginContent_inner">
            <h4>Welcome back!</h4>
            <p>Please sign in to continue</p>
            <form onSubmit={handleSubmit}>
            <div className="loginInputs">
              <div className="loginInput_inner">
                <input
                  type="text"
                  name='username'
                  placeholder="Username"
                  value={credentials.username}
                  onChange={handleChange}
                />
                <i>
                  <AiOutlineUser />
                </i>
              </div>
              <div className="loginInput_inner">
                <input
                  id="viewInput"
                  name='password'
                  type={passwordVisility ? "text" : "password"}
                  placeholder="Password"
                  value={credentials.password}
                  onChange={handleChange}
                />
                <i
                  onClick={() => setPasswordVisiblity(!passwordVisility)}
                  style={{ cursor: "pointer" }}
                >
                  {passwordVisility ? <FaRegEye /> : <FaRegEyeSlash />}
                </i>
              </div>
            </div>
            <div className="loginForgot_link">
              <Link to="/forget_password">forgot password?</Link>
            </div>
            <div className="loginFooter_btn">
              <button className="btnPrimary mb-2" type="submit" id="viewBtn">
                Login
              </button>
            </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Login;
