import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import "./MyChart.css";
const MyChart = (props) => {
//   console.log("data", props);
  const root = document.documentElement;
  const rootStyles = getComputedStyle(root);
  const rootColor = rootStyles.getPropertyValue("--colorPrimary");
  // const chartMinedValue = props?.stake - props?.mined;

  const data = [
    {
      title: "Pending Withdrawals",
      value: props?.pendingWithdrawals,
      color: "#EFC517",
    },
    {
        title: "Approved Withdrawls",
        value: props?.approvedWithdrawals,
        color: "green",
    },
    {
        title: "Rejected Withdrawal",
        value: props?.rejectedWithdrawals,
        color: "red",
    },
  ];

  return (
    <div className="capping">
        <h3>WITHDRAWAL</h3>
      <div className="cappingGraphDiv">
        <div style={{ height: "120px" }}>
          <PieChart
            animate={true}
            animationDuration={2000}
            data={data}
            lineWidth={30}
            paddingAngle={2}
            label={({ dataEntry }) => `${Math.round(dataEntry.percentage)}%`}
            labelStyle={{
              fill: "grey",
              fontSize: "5px",
              fontFamily: "sans-serif",
              fontSize: "8px",
              fontWeight: "bold",
              borderRadius: "50%",
              backgroundColor: "red !important",
            }}
          />
        </div>
        <div className="cappingAbout ">
          <div>
            <span style={{ background: "#EFC517" }}></span>
            <p>Pending</p>
          </div>
          <div>
            <span style={{ background: "green" }}></span>
            <p>Approved</p>
          </div>
          <div>
            <span style={{ background: "red" }}></span>
            <p>Rejected</p>
          </div>
        </div>
      </div>

      <div className="cappingDetails cappingDetailsDashboard">
        
          <div>
            <h3>{props.totalWithdrawals}</h3>
            <p>Total</p>
          </div>
          <div>
            <h3>{props.pendingWithdrawals}</h3>
            <p>Pending</p>
          </div>
        
        
          <div>
            <h3>{props.approvedWithdrawals}</h3>
            <p>Approved</p>
          </div>
          <div>
            <h3>{props.rejectedWithdrawals}</h3>
            <p>Rejected</p>
          </div>
        
      </div>
    </div>
  );
};

export default MyChart;
