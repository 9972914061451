import React, { useState, useCallback } from "react";
import "./AllUsers.css";
import { Container, Row, Col } from "react-bootstrap";
import { BiFilter } from "react-icons/bi";
import { GrPowerReset } from "react-icons/gr";
import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUsers } from "../../Actions/adminActions";
import PaginationComponent from "../../Component/Pagination/PaginationControls";
import apiCall from "./../../API/API";
import moment from "moment";
import debounce from "lodash.debounce";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const AllUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.admin);
  const [filter, setFilter] = useState("none");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limitFilter, setLimitFilter] = useState(20);
  const [search, setsearch] = useState({
    name: "",
    username: "",
    startDateJoining: "",
    endDateJoining: "",
    /*fromDate:'',
    toDate:'',*/
    status: 0,
  });
  const [dateFilter, setDateFilter] = useState("default");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const fetchUsers = useCallback(
    debounce((params) => {
      dispatch(fetchAllUsers(params));
    }, 500),
    [dispatch]
  );

  const toggleFilter = () => {
    if (filter === "none") {
      setFilter("flex");
    } else {
      setFilter("none");
    }
  };

  const handlePagination = (page) => {
    // console.log("page",page)
    setCurrentPage(page);
  };

  // const handleChange = (e) => {
  //   setsearch({ [e.target.name]: e.target.value })
  // }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") {
      setStartDate(value);
    } else if (name === "endDate") {
      setEndDate(value);
    } else {
      setsearch((prev) => {
        const updatedSearch = { ...prev, [name]: value };
        Object.keys(updatedSearch).forEach((key) => {
          if (updatedSearch[key] === "" || updatedSearch[key] === 0) {
            delete updatedSearch[key];
          }
        });
        return updatedSearch;
      });
    }
    fetchUsers({
      page: currentPage,
      limit: limitFilter,
      ...Object.fromEntries(
        Object.entries(search).filter(
          ([_, value]) => value !== "" && value !== 0
        )
      ),
      ...(startDate && endDate ? { startDate, endDate } : {}),
    });
  };

  const handleLimitChange = (e) => {
    setLimitFilter(e.target.value);
  };

  const handleDateFilterChange = (e) => {
    const value = e.target.value;
    setDateFilter(value);
    const today = moment().endOf("day");
    let start = "";
    let end = "";
    switch (value) {
      case "today":
        start = today.startOf("day").format("YYYY-MM-DD");
        end = today.format("YYYY-MM-DD");
        break;
      case "yesterday":
        start = today.subtract(1, "days").startOf("day").format("YYYY-MM-DD");
        end = today.endOf("day").format("YYYY-MM-DD");
        break;
      case "this_week":
        start = today.startOf("week").format("YYYY-MM-DD");
        end = today.endOf("week").format("YYYY-MM-DD");
        break;
      case "this_month":
        start = today.startOf("month").format("YYYY-MM-DD");
        end = today.endOf("month").format("YYYY-MM-DD");
        break;
      default:
        start = "";
        end = "";
        break;
    }
    setStartDate(start);
    setEndDate(end);
  };

  const AddFilter = () => {
    const queryParams = {
      page: currentPage,
      limit: limitFilter,
      ...Object.fromEntries(
        Object.entries(search).filter(
          ([_, value]) => value !== "" && value !== 0
        )
      ),
    };
    dispatch(fetchAllUsers(queryParams));
  };

  const loginUser = async (username) => {
    console.log("userId", username);
    try {
      const { data, error } = await apiCall(
        "POST",
        "/login-user",
        { username },
        true
      );
      if (data) {
        window.open(`https://gamepitara.com/?token=${data.token}`, "_blank");
        // window.open(`http://192.168.0.132:3000/?token=${data.token}`, "_blank");
      }
    } catch (error) {
      toast.error(error.message || "Login failed");
    }
  };

  const resetFilter = () => {
    const queryParams = {
      page: "1",
      limit: 20,
    };
    dispatch(fetchAllUsers(queryParams));
    setsearch({
      name: "",
      username: "",
      startDateJoining: "",
      endDateJoining: "",
      /*fromDate:'',
    toDate:'',*/
      status: 0,
    });
    setLimitFilter(20);
    setDateFilter("default");
    setStartDate("");
    setEndDate("");
    setCurrentPage(1);
  };

  useEffect(() => {
    //console.log("userData",userData);
    // console.log("passing",currentPage)
    const queryParams = {
      page: currentPage,
      limit: limitFilter,
      ...Object.fromEntries(
        Object.entries(search).filter(
          ([_, value]) => value !== "" && value !== 0
        )
      ),
    };
    if (startDate && endDate) {
      queryParams.startDate = startDate;
      queryParams.endDate = endDate;
    }
    fetchUsers(queryParams);
  }, [
    dispatch,
    currentPage,
    limitFilter,
    search,
    startDate,
    endDate,
    fetchUsers,
  ]);

  useEffect(() => {
    setTotalPages(userData?.totalPages);
  }, [userData]);

  return (
    <React.Fragment>
      <Container>
        <div className="pagePath">
          <p className="m-0">Home / All Users</p>
        </div>
        <Row id="filterRow">
          <span id="filterLogo1" onClick={() => toggleFilter()}>
            <i>
              <BiFilter />
            </i>
            Filter
          </span>
          <Row className="p-0 m-0" style={{ display: filter }}>
            <Col lg="2" md="4">
              <div>
                <input
                  type="text"
                  placeholder="Search By Name"
                  name="name"
                  value={search.name}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <input
                  type="text"
                  placeholder="Search User By Username"
                  name="username"
                  value={search.username}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <input
                  type="date"
                  placeholder="from date"
                  name="startDateJoining"
                  value={search.startDateJoining}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <input
                  type="date"
                  placeholder="to date"
                  name="endDateJoining"
                  value={search.endDateJoining}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <select
                  name="dateFilter"
                  value={dateFilter}
                  onChange={handleDateFilterChange}
                >
                  <option value="default">Default</option>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="this_week">This Week</option>
                  <option value="this_month">This Month</option>
                </select>
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <select
                  name="limit"
                  value={limitFilter}
                  onChange={handleLimitChange}
                >
                  <option value="">--All--</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <select
                  name="status"
                  value={search.status}
                  onChange={handleChange}
                >
                  <option value="">--Status--</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </Col>
            <div>
              <button className="save filter" onClick={AddFilter}>
                Filter{" "}
                <i>
                  <BiFilter />
                </i>
              </button>
              <button className="cancel reset" onClick={resetFilter}>
                Reset{" "}
                <i>
                  <GrPowerReset />
                </i>
              </button>
            </div>
          </Row>
        </Row>
        <Row id="directTableRow">
          <Col md="12" className="p-0">
            <div style={{ width: "100%" }}>
              <table>
                <tr>
                  <th>S No.</th>
                  <th>Name</th>
                  <th>Login</th>
                  <th>Username</th>
                  <th>Email Address</th>
                  <th>Mobile Number</th>
                  <th>Joining Date</th>
                  <th>Activation Date</th>
                  <th>Sponsor</th>
                  <th>Wallet</th>
                  <th>Edit Profile</th>
                  <th>Action</th>
                </tr>

                {userData?.data?.map((x, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1 + limitFilter * (currentPage - 1)}</td>
                      <td>
                        <div
                          className={`status-dot ${
                            x?.status === 0 ? "inactive" : "active"
                          }`}
                        />
                        {x?.name}
                      </td>
                      <td>
                        <button
                          className="block-btn"
                          onClick={() => loginUser(x.username)}
                        >
                          Login
                        </button>
                      </td>
                      <td>{x?.username}</td>
                      <td>{x?.email}</td>
                      <td>{x?.mobile}</td>
                      <td>
                        {moment(x?.joining_date).format(
                          "MM-DD-YYYY   &   HH:mm"
                        )}
                      </td>
                      <td>
                        {moment(x?.Activation_date).format(
                          "MM-DD-YYYY  &  HH:mm"
                        )}
                      </td>

                      <td>{x.sponsor_username}</td>
                      <td></td>
                      <td>
                        <button
                          className="block-btn"
                          onClick={() =>
                            navigate("/dashboard/edit-profile", {
                              state: { user: x },
                            })
                          }
                        >
                          Edit Profile
                        </button>
                      </td>
                      <td>
                        {/*<ToggleBlockButton
                          isBlocked={x?.is_blocked === 1}
                          userId={x.uid}
                          
                        />*/}
                        {x?.is_blocked === 0 ? (
                          <button className="btn btn-danger block-btn">
                            Block
                          </button>
                        ) : (
                          <button className="btn btn-success block-btn">
                            Unblock
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            {
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePagination}
              />
            }
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AllUsers;
