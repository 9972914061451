import React, { useState, useCallback } from "react";
import "./With.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Change from "../../Common/StringToSub";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTransaction } from "../../Actions/adminActions";
import { BiFilter } from "react-icons/bi";
import { GrPowerReset } from "react-icons/gr";
import { HiDownload } from "react-icons/hi";
import PaginationComponent from "../../Component/Pagination/PaginationControls";
import moment from "moment";
import debounce from "lodash.debounce";
import Popup from "reactjs-popup";
const ApprovedWith = () => {
  const { paymentRequests } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filter, setFilter] = useState("none");
  const [search, setsearch] = useState({
    source: "withdraw",
    status: "1",
    amount: "",
    username: "",
    search: "",
    startDate: "",
    endDate: "",
  });

  const [limitFilter, setLimitFilter] = useState(20);
  const [dateFilter, setDateFilter] = useState("default");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const fetchWithdraw = useCallback(
    debounce((params) => {
      dispatch(fetchAllTransaction(params));
    }, 500),
    [dispatch]
  );

  const toggleFilter = () => {
    if (filter === "none") {
      setFilter("flex");
    } else {
      setFilter("none");
    }
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") {
      setStartDate(value);
    } else if (name === "endDate") {
      setEndDate(value);
    } else {
      setsearch((prev) => {
        const updatedSearch = { ...prev, [name]: value };
        Object.keys(updatedSearch).forEach((key) => {
          if (updatedSearch[key] === "" || updatedSearch[key] === 0) {
            delete updatedSearch[key];
          }
        });
        return updatedSearch;
      });
    }
    fetchWithdraw({
      page: currentPage,
      limit: limitFilter,
      ...Object.fromEntries(
        Object.entries(search).filter(
          ([_, value]) => value !== "" && value !== 0
        )
      ),
      ...(startDate && endDate ? { startDate, endDate } : {}),
    });
  };
  const handleLimitChange = (e) => {
    setLimitFilter(e.target.value);
  };

  const handleDateFilterChange = (e) => {
    const value = e.target.value;
    setDateFilter(value);
    const today = moment().endOf("day");
    let start = "";
    let end = "";
    switch (value) {
      case "today":
        start = today.startOf("day").format("YYYY-MM-DD");
        end = today.format("YYYY-MM-DD");
        break;
      case "yesterday":
        start = today.subtract(1, "days").startOf("day").format("YYYY-MM-DD");
        end = today.endOf("day").format("YYYY-MM-DD");
        break;
      case "this_week":
        start = today.startOf("week").format("YYYY-MM-DD");
        end = today.endOf("week").format("YYYY-MM-DD");
        break;
      case "this_month":
        start = today.startOf("month").format("YYYY-MM-DD");
        end = today.endOf("month").format("YYYY-MM-DD");
        break;
      default:
        start = "";
        end = "";
        break;
    }
    setStartDate(start);
    setEndDate(end);
  };

  const Addfilter = () => {
    const queryParams = {
      page: currentPage,
      limit: limitFilter,
      ...Object.fromEntries(
        Object.entries(search).filter(
          ([_, value]) => value !== "" && value !== 0
        )
      ),
    };

    if (startDate) {
      queryParams.startDate = startDate;
    }
    if (endDate) {
      queryParams.endDate = endDate;
    }

    dispatch(fetchAllTransaction(queryParams));
  };

  const resetFilter = () => {
    setsearch({
      source: "withdraw",
      status: "1",
      amount: "",
      username: "",
      search: "",
      startDate: "",
      endDate: "",
    });
    setLimitFilter(20);
    setStartDate("");
    setEndDate("");
    setDateFilter("default");
    setCurrentPage(1);

    dispatch(
      fetchAllTransaction({
        page: 1,
        limit: 20,
        source: "withdraw",
        status: "1",
      })
    );
  };

  useEffect(() => {
    const queryParams = {
      page: currentPage,
      limit: 20,
      ...Object.fromEntries(
        Object.entries(search).filter(
          ([_, value]) => value !== "" && value !== 0
        )
      ),
    };
    if (startDate && endDate) {
      queryParams.startDate = startDate;
      queryParams.endDate = endDate;
    }
    fetchWithdraw(queryParams);
  }, [dispatch, currentPage, limitFilter, search, startDate, endDate]);

  useEffect(() => {
    setTotalPages(paymentRequests?.totalPages);
  }, [paymentRequests]);
  return (
    <React.Fragment>
      <Container>
        <Row id="filterRow">
          <span className="filterL">
            <div className="d-flex justify-content-between">
              <div onClick={() => toggleFilter()}>
                <i>
                  <BiFilter />
                </i>
                Filter
              </div>
              <div className="align-content-center">
                Total Debited Amount : {paymentRequests?.approvedSum}
              </div>
            </div>
          </span>

          <Row className="p-0 m-0" style={{ display: filter }}>
            <Col lg="2" md="4">
              <div>
                <input
                  type="text"
                  name="search"
                  placeholder="Search By Name, Username"
                  value={search.search}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <input
                  type="number"
                  name="amount"
                  placeholder="Search By Amount"
                  value={search.amount}
                  onChange={handleChange}
                />
              </div>
            </Col>
            {/*<Col lg="2" md="4">
              <div>
              
              <input type="text" name='username' placeholder="Search By UserName" value={search.username} onChange={handleChange} />
               </div>
            </Col>*/}
            <Col lg="2" md="4">
              <div>
                <input
                  type="date"
                  name="startDate"
                  placeholder="Start Date"
                  value={startDate}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <input
                  type="date"
                  name="endDate"
                  placeholder="End Date"
                  value={endDate}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <select
                  name="dateFilter"
                  value={dateFilter}
                  onChange={handleDateFilterChange}
                >
                  <option value="default">Default</option>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="this_week">This Week</option>
                  <option value="this_month">This Month</option>
                </select>
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <select
                  name=""
                  value={limitFilter}
                  onChange={handleLimitChange}
                >
                  <option value="">--All--</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>
            </Col>
            <div>
              <button className="save filter" onClick={Addfilter}>
                Filter{" "}
                <i>
                  <BiFilter />
                </i>
              </button>
              <button className="cancel reset" onClick={resetFilter}>
                Reset{" "}
                <i>
                  <GrPowerReset />
                </i>
              </button>
            </div>
          </Row>
        </Row>

        <div id="directIncomeDiv">
          <Row id="directTableRow" className="text-center">
            <Col md="12" className="p-0">
              <div style={{ width: "100%" }}>
                <table>
                  <tr>
                    <th>S. No.</th>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Amount</th>
                    <th>Account Details</th>
                    <th>Transaction id</th>
                    <th>Date</th>
                  </tr>
                  {paymentRequests?.data?.map((row, index) => (
                    <tr key={index}>
                      {/* <td>
                <input
                  type="checkbox"
                  checked={selectedRows.includes(index)}
                  onChange={() => toggleSelectRow(index, row.tx_Id)}
                  className="checkbox-input"
                  id={`row-${index}`}
                />
                <label htmlFor={`row-${index}`} className="checkbox-label">
                  <span
                    className={`custom-checkbox ${
                      selectedRows.includes(index) ? "checked" : ""
                    }`}
                  />
                </label>
              </td> */}
                      <td>{index + 1 + limitFilter * (currentPage - 1)}</td>
                      <td>{row.name}</td>
                      <td>{row.username}</td>
                      <td>{row.amount}</td>
                      <td>
                        {row?.account &&
                          Object.entries(row?.account)?.map(([key, value]) => (
                            <div className="keyValue d-flex align-items-center gap-2">
                              <p>{key} :</p>
                              <p>{value}</p>
                            </div>
                          ))}
                      </td>
                      {/* <td style={{textAlign:"center"}}>
              <Popup 
                trigger={<button className="block-btn">View Proof</button>}  
                modal
                closeOnDocumentClick>
                  {close => (
                <div className="popup-content">
                  <button className="close-btn" onClick={close}>
                    &times;
                  </button>
                  <img src={row.proofUrl} alt="proof" className="popImg" />
                  <button 
        className="download-btn" 
        onClick={() => {
          const link = document.createElement('a');
          link.href = row.proofUrl;
          link.download = "proof.jpg"; 
          link.click();
        }}
      >
        <HiDownload />       
      </button>
                </div>
              )}
              </Popup>
            </td> */}
                      <td>{row.tx_Id}</td>
                      <td>
                        {moment(row?.createdAt).format(
                          "DD-MM-YYYY   &   HH:mm"
                        )}
                      </td>
                      {/* {row.accountDetails.hasOwnProperty('bank_details') && <td>
                           <p>Bank Name : {row.accountDetails.bank_details.bank_name}</p>
                           <p>IFSC Code : {row.accountDetails.bank_details.ifsc_code}</p>
                           <p>Account Number : {row.accountDetails.bank_details.account_number}</p>
                           <p>Account Holder Name : {row.accountDetails.bank_details.account_holder_name}</p>
                           <p>Account Type : {row.accountDetails.bank_details.account_type}</p>
                           </td>}
                           {row.accountDetails.hasOwnProperty('crypto_address') && <td>Wallet Address : {row.accountDetails.crypto_address}</td>}
                           {row.accountDetails.hasOwnProperty('UPI_ID') && <td>UPI ID : {row.accountDetails.UPI_ID}</td>} */}
                    </tr>
                  ))}
                </table>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md="12">
            {
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePagination}
              />
            }
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ApprovedWith;
