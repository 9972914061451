import React from "react";
import { Routes, Route } from "react-router-dom";
// import Check from "./Pages/Check/Check";
// import Test from "./Test";
// import WelcomeLetter from "./Pages/WelcomeLetter/WelcomeLetter";
// import ProfilePage from "./Pages/ProfilePage/ProfilePage";
// import EditProfile from "./Pages/EditProfile/EditProfile";
// import Accounts from "./Pages/Accounts/Accounts";
// import Generation from "./Pages/Generation/Generation";
// import WithdrawalReport from "./Pages/WithdrawalReport/WithdrawalReport";
// import KYC from "./Pages/KYC/KYC";
// import PinTransfer from "./Pages/PinTransfer/PinTransfer";
// import PinHistory from "./Pages/PinHistory/PinHistory";
// import PinBox from "./Pages/PinBox/PinBox";
// import MemberTopup from "./Pages/MemberTopup/MemberTopup";
// import RewardIncome from "./Pages/ROILevelIncome/ROILevelIncome";
// import Rewards from "./Pages/Rewards/Rewards";
// import ReportPage from "./Pages/ReportPage/ReportPage";
// import NewsEvent from "./Pages/NewsEvent/NewsEvent";
// import Support from "./Pages/Support/Support";
// import Tree from "./Pages/Tree/Tree";
import AllUsers from "./Pages/Users/AllUsers";
import Dashboard from "./Pages/Dashboard/Dashboard";
import PendingFund from "./Pages/FundRequest/Pending";
import ApprovedFund from "./Pages/FundRequest/Approved";
import RejecteddFund from "./Pages/FundRequest/Rejected";
import PendingWith from "./Pages/withdrawal/PendingWith";
import ApprovedWith from "./Pages/withdrawal/ApprovedWith";
import RejectedWith from "./Pages/withdrawal/RejectedWith";
import EditProfile from "./Pages/EditProfile/EditProfile";
import FundsManagement from "./Pages/FundRequest/FundControls";
import WithdrawManagement from "./Pages/withdrawal/WithdrawControls";
import WithdrawDetails from "./Pages/withdrawal/WithdrawDetails";
import BankDetails from "./Pages/BankReports/BankDetails";
import UPI from "./Pages/BankReports/Upi"
import Orders from "./Pages/Orders/Orders";
import PayoutManagement from "./Pages/PayoutReport/PayoutControls"
import ROILevelIncome from "./Pages/PayoutReport/ROILevelIncome";
import RoyaltyIncome from "./Pages/PayoutReport/RoyaltyIncome";
import LevelIncome from "./Pages/PayoutReport/LevelIncome";
import DirectIncome from "./Pages/PayoutReport/RoiIncome";
import AddFunds from "./Pages/AddFund/AddFund";
import FundsHistory from "./Pages/FundHistory/FundHistory";
import Banner from "./Pages/Banner/Banner"
// import SingleLegGoal from "./Pages/singleLeg/SingleLegGoal";
// import PendingWith from "./Pages/withdrawal/PendingWith";
// import ApprovedWith from "./Pages/withdrawal/ApprovedWith";
// import RejecteddWith from "./Pages/withdrawal/RejectedWith";
// import AddFunds from "./Pages/AddFund/AddFund";
// import TableForm from "./Pages/PlanSetting/TableForm";
// import PendingFundRequestsPage from "./Pages/FundRequests/PendingFundRequestsPage";
// import AddPackage from "./Pages/AddPackage/AddPackage";
const NavPages = () => {
    return (
        <React.Fragment>
            <section>
                <Routes>
                    <Route path="/"   element={<Dashboard />} />
                    <Route path="/pending_fund"   element={<PendingFund />} />
                    <Route path="/approved_fund"   element={<ApprovedFund />} />
                    <Route path="/rejected_fund"   element={<RejecteddFund />} />
                    <Route path="/fund_controls"   element={<FundsManagement />} />
                    <Route path="/withdraw_controls"   element={<WithdrawManagement />} />
                    <Route path="/withdraw_details"   element={<WithdrawDetails />} />
                    {/* <Route path="/withdraw_details" element={<WithdrawDetails/>} /> */}
                    <Route path="/pending_withdrawal"   element={<PendingWith />} />
                    <Route path="/approved_withdrawal"   element={<ApprovedWith />} />
                    <Route path="/rejected_withdrawal"   element={<RejectedWith />} />
                    <Route path="/all_users"   element={<AllUsers />} />
                    <Route path="/payout_controls"   element={<PayoutManagement />} />                                                                                                                                                   
                    <Route path="/select_upi"   element={<UPI />} />
                    <Route path="/orders"   element={<Orders />} />
                    <Route path="/edit-profile"   element={<EditProfile />} />
                    <Route path="/roilevel_income"   element={<RoyaltyIncome />} />
                    <Route path="/roi_income"   element={<DirectIncome />} />
                    <Route path="/royalty_income"   element={<ROILevelIncome />} />
                    <Route path="/level_income"   element={<LevelIncome />} />
                    <Route path="/bank"   element={<BankDetails />} />
                    <Route path="/add_fund"   element={<AddFunds />} />
                    <Route path="/fund_history"   element={<FundsHistory />} />
                    <Route path="/banner"   element={<Banner />} />
                    {/* <Route path="/check"   element={<Check />} />
                    <Route path="/test"   element={<Test />} />
                    <Route path="/welcome-letter"   element={<WelcomeLetter />} />
                    <Route path="/profile-page"   element={<ProfilePage />} />
                    <Route path="/edit-profile"   element={<EditProfile />} />
                    <Route path="/accounts"   element={<Accounts />} />
                    <Route path="/add_fund"   element={<AddFunds />} />
                    <Route path="/pending_withdrawal"   element={<PendingWith />} />
                    <Route path="/approved_withdrawal"   element={<ApprovedWith />} />
                    <Route path="/rejected_withdrawal"   element={<RejecteddWith />} />
                    <Route path="/generation"   element={<Generation />} />
                    <Route path="/deposit_withdrawal"   element={<WithdrawalReport />} />
                    <Route path="/kyc"   element={<KYC />} />
                    <Route path="/pin-transfer"   element={<PinTransfer />} />
                    <Route path="/pin-history"   element={<PinHistory />} />
                    <Route path="/pinbox"   element={<PinBox />} />
                    <Route path="/member-topup"   element={<MemberTopup />} />
                    <Route path="/orders"   element={<Orders />} />
                    <Route path="/Single_Leg_Goal"   element={<SingleLegGoal/>} />
                    <Route path="/rewards"   element={<Rewards />} />
                    <Route path="/report"   element={<ReportPage />} />
                    <Route path="/news-event"   element={<NewsEvent />} />
                    <Route path="/tree"   element={<Tree />} />
                    <Route path="/plan_setting"   element={<TableForm />} />
                    <Route path="/active_package"   element={<AddPackage />} /> */}
                    {/* <Route path="/pending_fund"   element={<PendingFundRequestsPage />} /> */}
                </Routes>
            </section>
        </React.Fragment>
    );
};

export default NavPages;